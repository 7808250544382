import React from "react";
import BgHero from "../BgHero";
import bgHero from "../../images/menu/hero.jpg";
import Menu from "../menu/Menu";
import salades from "./menuGastro/salades.json";
import entreeChaude from "./menuGastro/entreeChaude.json";
import pizzas from "./menuGastro/pizzas.json";
import pates from "./menuGastro/pates.json";
import gratinsLasagnes from "./menuGastro/gratinsLasagnes.json";
import gastronomiques from "./menuGastro/gastronomiques.json";
import specialiteMarocaine from "./menuGastro/specialiteMarocaine.json";
import desserts from "./menuGastro/desserts.json";
import fondue from "./menuGastro/fondue.json";
import raclette from "./menuGastro/raclette.json";

import lasagneSpecial from "./menuSpecial/lasagne.json";
import gratinSpecial from "./menuSpecial/gratin.json";
import patesSpecial from "./menuSpecial/pates.json";
import francaiseSpecial from "./menuSpecial/francaise.json";
import italienneSpecial from "./menuSpecial/italienne.json";

import finger from "./tapas/finger.json";
import platters from "./tapas/platters.json";
import croques from "./tapas/croques.json";
import grandes from "./tapas/grandes.json";

export default function Main() {
  const tabMenuGastro = [
    "Salades",
    "Entrée Chaude",
    "Pizzas",
    "Pâtes",
    "Gratins & Lasagnes",
    "Nos Plats Gastronomiques",
    "Spécialité Marocaine",
    "Desserts",
    "Fondue",
    "Raclette",
  ];

  const menuGastro = [
    salades,
    entreeChaude,
    pizzas,
    pates,
    gratinsLasagnes,
    gastronomiques,
    specialiteMarocaine,
    desserts,
    fondue,
    raclette,
  ];

  const tabMenuSpecial = [
    "Menu Special Lasagne",
    "Menu Special Gratin",
    "Menu Special Pates",
    "Menu Special Francaise",
    "Menu Special Italienne",
  ];

  const menuSpecial = [
    lasagneSpecial,
    gratinSpecial,
    patesSpecial,
    francaiseSpecial,
    italienneSpecial,
  ];

  const menuTapas = [finger, platters, croques, grandes];

  const tabMenuTapas = [
    "Finger Food",
    "Les Platters A Partager",
    "Les Croques ET Les Flammekueches",
    "Pour Les Plus Grandes Faims",
  ];

  const headingStyles = {
    textAlign: "center",
    marginBottom: "50px",
    fontSize: "2.5rem",
  };

  return (
    <main>
      <BgHero heading="Food" bg={bgHero} />
      <div className="container" style={{ margin: "75px auto" }}>
        <h2 style={headingStyles}>Menu Tapas</h2>
        <Menu tabMenu={tabMenuTapas} menuItems={menuTapas} />
        <h2 style={headingStyles}>Menu Gastro</h2>
        <Menu tabMenu={tabMenuGastro} menuItems={menuGastro} />
        <h2 style={headingStyles}>Menu Special</h2>
        <Menu tabMenu={tabMenuSpecial} menuItems={menuSpecial} />
      </div>
    </main>
  );
}
