import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Main from "../components/Food/Main";
import { Helmet } from "react-helmet";
export default function Food() {
  return (
    <>
      <Helmet title="Food Menu - La Noce" defer={false} />
      <Nav />
      <Main />
      <Footer />
    </>
  );
}
